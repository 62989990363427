<template>
  <sub-page>

    <ws-data-table
        class="mt-5"
        :items="items"
        :headers="headers"
        :row-action="openLetter"
    >

    </ws-data-table>

    <email-preview-dialog
        v-if="displayPreview"
        v-model="displayPreview"
        :uuid="emailDesignId"
        super-admin
        disable-mobile
    >
      <template #default>
        <div class="fill-height d-flex align-center justify-center" v-if="displayApprove || displayDeny">
          <div v-if="displayApprove">
            <h4 class="wsACCENT font-weight-regular text-center" style="max-width: 400px">
              Чи впевнені що хочете надати дозвіл даному листу та запустити його на відправку?
            </h4>

            <div class="d-flex justify-center mt-4">
              <ws-switch v-model="improveTrust" placeholder="Підвищити рівень довіри клієнту" />
            </div>


            <div class="d-flex justify-center">
              <ws-button @click="approveNewsletter" class="mt-6" :color="wsSUCCESS" :disabled="LOADING" label="Відправити лист" />
            </div>
          </div>

          <div v-if="displayDeny">

            <h4 class="wsDARKER font-weight-regular text-center" style="max-width: 500px">
              Підтвердити відмову клієнту в відправленні листа?
            </h4>

            <h5 class="mt-4 font-weight-regular text-center wsACCENT"  style="max-width: 500px">
              Якщо ви маєте сумніви стосовно клієнта - окрім заборони листа негайно оповістіть технічний відділ для блокування клієнта
            </h5>

            <ws-text-field
                width="500"
                v-model="denyComment"
                label="Коментар щодо відмови"
                class="mt-6"
                area
            />

            <div class="d-flex justify-center">
              <ws-button @click="denyNewsletter" :color="wsWARNING" :disabled="LOADING" class="mt-6" label="Відмовити у відправленні" />
            </div>
          </div>



        </div>
      </template>
      <template #footer>

        <div v-if="displayApprove || displayDeny" class="pb-6 px-8">
          <ws-button @click="displayDeny = false; displayApprove = false" :color="wsACCENT" label="Назад"  outlined class="mr-3" />
        </div>
        <div v-else class="d-flex justify-end pb-6 px-8">
          <ws-button @click="displayDeny = true" :color="wsWARNING" label="Відмовити" class="mr-3"></ws-button>
          <ws-button @click="displayApprove = true" :color="wsSUCCESS" label="Дозволити"></ws-button>
        </div>

      </template>
    </email-preview-dialog>

  </sub-page>

</template>

<script>
import {mapActions} from "vuex";
import emailPreviewDialog from "@modules/finemailer/components/emailConstructor/viewer/emailPreviewDialog.vue";

export default {
  name: "adminSupportEmailModeration",
  components: {emailPreviewDialog},
  data() {
    return {

      displayDeny : false,
      displayApprove : false,
      improveTrust : false,
      denyComment : '',

      displayPreview : false,
      emailDesignId : false,
      selectedNewsletter : {},


      items : [],
      headers : [
        { text : "Клієнт" , value : 'business_name' },
        { text : "Розсилка" , value : 'name' },
        { text : "Причина" , value : 'review_reason' },
        { text : "Отримувачі" , value : 'receivers_count' }
      ]
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'GET_MODERATION_NEWSLETTERS',
      "DENY_MODERATION_NEWSLETTER",
      "ALLOW_MODERATION_NEWSLETTER",
    ]),

    async denyNewsletter() {
      let data = {
        uuid : this.selectedNewsletter.uuid,
        comment : this.denyComment
      }
      let result = await this.DENY_MODERATION_NEWSLETTER(data)

      if (!result) {
        return this.ERROR()
      }

      this.displayPreview = false

      let index = this.items.findIndex(el => el.uuid === this.selectedNewsletter.uuid)
      if (index === -1) {
        return
      }
      this.items.splice(index, 1)

      this.notify('Розсилку скасовано')

    },
    async approveNewsletter() {

      let data = {
        uuid : this.selectedNewsletter.uuid,
        improve_trust : this.improveTrust
      }

      let result = await this.ALLOW_MODERATION_NEWSLETTER(data)

      if (!result) {
        return this.ERROR()
      }

      this.displayPreview = false

      let index = this.items.findIndex(el => el.uuid === this.selectedNewsletter.uuid)
      if (index === -1) {
        return
      }
      this.items.splice(index, 1)

      this.notify('Розсилку схвалено та направлено на відправку' , 'success')
    },

    openLetter(item) {
      this.displayDeny = false
      this.displayApprove = false
      this.improveTrust = true
      this.denyComment = ''
      this.selectedNewsletter = this.COPY(item)
      this.emailDesignId = item.newsletter_design_id
      this.displayPreview = true

    },
    async initPage() {
      let result = await this.GET_MODERATION_NEWSLETTERS()
      if (!result) {
        return this.ERROR(this.$store.state.ajax.error)
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>